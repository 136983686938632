import * as Yup from 'yup';

export const RegisterDataSchema = Yup.object().shape({
  name: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('First name required'),
  surname: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Last name required'),
  username: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Username required'),
  email: Yup.string().email('Email must be a valid email address').required('Email is required'),
  birthday: Yup.string().required('Birthday is required'),
  phone: Yup.string().required('Phone number is required'),
  countryCode: Yup.string().required('Country is required'),
  password: Yup.string()
    .required('Password is required')
    .min(6, 'Password should be of minimum 6 characters length'),
  confirmPassword: Yup.string()
    .required('Confirm password is required')
    .oneOf([Yup.ref('password')], "Password's not match"),
});

export const RegisterDocumentSchema = Yup.object().shape({
  // personalDocument: Yup.object()
  //   .shape({
  //     name: Yup.string().required(),
  //   })
  //   .label('File'),
  // proofOfAddressDocument: Yup.object()
  //   .shape({
  //     name: Yup.string().required(),
  //   })
  //   .label('File'),
});

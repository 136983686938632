import { LoadingButton } from '@mui/lab';
import DatePicker from '@mui/lab/DatePicker';
// @mui
import { Alert, IconButton, InputAdornment, Stack, TextField } from '@mui/material';
import { Form, FormikProps, FormikProvider } from 'formik';
import { useState } from 'react';
import Iconify from 'src/components/Iconify';
// _mock
import { countries } from 'src/_mock';
import { DataInitialValues } from './RegisterForm';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

type PropsType = {
  formik: FormikProps<DataInitialValues>;
};
export default function RegisterDataStep({ formik }: PropsType) {
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);

  const { errors, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label="First name"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />

            <TextField
              fullWidth
              label="Last name"
              {...getFieldProps('surname')}
              error={Boolean(touched.surname && errors.surname)}
              helperText={touched.surname && errors.surname}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
            <TextField
              fullWidth
              autoComplete="email"
              type="email"
              label="Email address"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              fullWidth
              autoComplete="username"
              label="Username"
              {...getFieldProps('username')}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
            />
          </Stack>

          <DatePicker
            label="Birthday"
            {...getFieldProps('birthday')}
            onChange={(date) => setFieldValue('birthday', date)}
            renderInput={(params) => (
              <TextField
                fullWidth
                {...params}
                error={Boolean(touched.birthday && errors.birthday)}
                helperText={touched.birthday && errors.birthday}
              />
            )}
            inputFormat="dd/MM/yyyy"
          />

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
            <TextField
              select
              fullWidth
              label="Country"
              placeholder="Country"
              {...getFieldProps('countryCode')}
              SelectProps={{ native: true }}
              error={Boolean(touched.countryCode && errors.countryCode)}
              helperText={touched.countryCode && errors.countryCode}
            >
              <option value="" />
              {countries.map((option) => (
                <option key={option.code} value={option.label}>
                  {option.label}
                </option>
              ))}
            </TextField>
            <TextField
              fullWidth
              label="Phone Number"
              {...getFieldProps('phone')}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              label="Password"
              {...getFieldProps('password')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              label="Confirm Password"
              {...getFieldProps('confirmPassword')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword((prev) => !prev)} edge="end">
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              helperText={touched.confirmPassword && errors.confirmPassword}
            />
          </Stack>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            {t('common.next')}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}

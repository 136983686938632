/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AbstractPageableResponseBonusIncomeRecord, PaymentHistoryDataPoint } from '../model';
// @ts-ignore
import { AbstractPageableResponseBonusReinvestRecord } from '../model';
// @ts-ignore
import { AbstractPageableResponsePaymentDTO } from '../model';
// @ts-ignore
import { AbstractPageableResponsePayoutDTO } from '../model';
// @ts-ignore
import { ClientAvailableFundsResponse } from '../model';
// @ts-ignore
import { ClientNoteDTO } from '../model';
// @ts-ignore
import { CurrentBalanceHistoryDataPoint } from '../model';
// @ts-ignore
import { IndexDistributionResponse } from '../model';
// @ts-ignore
import { TotalBalanceStatusResponse } from '../model';
// @ts-ignore
import { TotalInvestedPerYearResponse } from '../model';
// @ts-ignore
import { TotalStatisticPerMonthResponse } from '../model';
// @ts-ignore
import { UserDTO } from '../model';
/**
 * LoadClientDashboardRestAdapterApi - axios parameter creator
 * @export
 */
export const LoadClientDashboardRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAnnualInvestedByClientId: async (clientId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('loadAnnualInvestedByClientId', 'clientId', clientId)
            const localVarPath = `/client-dashboard/load-annual-invested/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAvailableFunds: async (userId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('loadAvailableFunds', 'userId', userId)
            const localVarPath = `/client-dashboard/load-available-funds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBalanceHistory: async (id: number, from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('loadBalanceHistory', 'id', id)
            const localVarPath = `/client-dashboard/load-balance-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBalanceHistoryByTrades: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('loadBalanceHistoryByTrades', 'id', id)
            const localVarPath = `/client-dashboard/load-balance-history-by-trades`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBonusIncomeByClientId: async (clientId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('loadBonusIncomeByClientId', 'clientId', clientId)
            const localVarPath = `/client-dashboard/load-bonus-income/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {string} [affiliateName] 
         * @param {string} [countryCode] 
         * @param {'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBonusIncomeHistory1: async (userId: number, page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, affiliateName?: string, countryCode?: string, type?: 'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('loadBonusIncomeHistory1', 'userId', userId)
            const localVarPath = `/client-dashboard/load-bonus-income-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (localDateFrom !== undefined) {
                localVarQueryParameter['localDateFrom'] = (localDateFrom as any instanceof Date) ?
                    (localDateFrom as any).toISOString().substr(0,10) :
                    localDateFrom;
            }

            if (localDateTo !== undefined) {
                localVarQueryParameter['localDateTo'] = (localDateTo as any instanceof Date) ?
                    (localDateTo as any).toISOString().substr(0,10) :
                    localDateTo;
            }

            if (affiliateName !== undefined) {
                localVarQueryParameter['affiliateName'] = affiliateName;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadClientNotesByClientId: async (clientId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('loadClientNotesByClientId', 'clientId', clientId)
            const localVarPath = `/client-dashboard/load-client-notes/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCurrentBalance: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('loadCurrentBalance', 'id', id)
            const localVarPath = `/client-dashboard/load-current-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'ESTABLISHED' | 'STARTUP' | 'ACTIVE'} positionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadHoldIndexDistributionByPositionType: async (positionType: 'ESTABLISHED' | 'STARTUP' | 'ACTIVE', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'positionType' is not null or undefined
            assertParamExists('loadHoldIndexDistributionByPositionType', 'positionType', positionType)
            const localVarPath = `/client-dashboard/load-hold-index-distribution/{positionType}`
                .replace(`{${"positionType"}}`, encodeURIComponent(String(positionType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} clientId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadPaymentsByClientId: async (clientId: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('loadPaymentsByClientId', 'clientId', clientId)
            const localVarPath = `/client-dashboard/load-payments/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} clientId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {'APPROVED' | 'PENDING' | 'DECLINED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadPayoutsByClientIdAndStatus: async (clientId: number, page?: number, size?: number, sort?: Array<string>, status?: 'APPROVED' | 'PENDING' | 'DECLINED', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('loadPayoutsByClientIdAndStatus', 'clientId', clientId)
            const localVarPath = `/client-dashboard/load-payouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadProfileByClientId: async (clientId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('loadProfileByClientId', 'clientId', clientId)
            const localVarPath = `/client-dashboard/load-profile/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadShortIndexDistribution: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-short-index-distribution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTotalInvestedByClientId: async (clientId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('loadTotalInvestedByClientId', 'clientId', clientId)
            const localVarPath = `/client-dashboard/load-total-balance-status/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTotalReferralsByClientId: async (clientId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('loadTotalReferralsByClientId', 'clientId', clientId)
            const localVarPath = `/client-dashboard/load-total-referrals/{clientId}`
                .replace(`{${"clientId"}}`, encodeURIComponent(String(clientId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reinvestHistory: async (userId: number, page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('reinvestHistory', 'userId', userId)
            const localVarPath = `/client-dashboard/load-bonus-reinvest-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (localDateFrom !== undefined) {
                localVarQueryParameter['localDateFrom'] = (localDateFrom as any instanceof Date) ?
                    (localDateFrom as any).toISOString().substr(0,10) :
                    localDateFrom;
            }

            if (localDateTo !== undefined) {
                localVarQueryParameter['localDateTo'] = (localDateTo as any instanceof Date) ?
                    (localDateTo as any).toISOString().substr(0,10) :
                    localDateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoadClientDashboardRestAdapterApi - functional programming interface
 * @export
 */
export const LoadClientDashboardRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoadClientDashboardRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadAnnualInvestedByClientId(clientId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalInvestedPerYearResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadAnnualInvestedByClientId(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadAvailableFunds(userId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAvailableFundsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadAvailableFunds(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadBalanceHistory(id: number, from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: CurrentBalanceHistoryDataPoint; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadBalanceHistory(id, from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadBalanceHistoryByTrades(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: PaymentHistoryDataPoint; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadBalanceHistoryByTrades(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadBonusIncomeByClientId(clientId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalStatisticPerMonthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadBonusIncomeByClientId(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {string} [affiliateName] 
         * @param {string} [countryCode] 
         * @param {'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadBonusIncomeHistory1(userId: number, page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, affiliateName?: string, countryCode?: string, type?: 'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponseBonusIncomeRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadBonusIncomeHistory1(userId, page, size, sort, localDateFrom, localDateTo, affiliateName, countryCode, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadClientNotesByClientId(clientId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientNoteDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadClientNotesByClientId(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadCurrentBalance(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentBalanceHistoryDataPoint>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadCurrentBalance(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'ESTABLISHED' | 'STARTUP' | 'ACTIVE'} positionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadHoldIndexDistributionByPositionType(positionType: 'ESTABLISHED' | 'STARTUP' | 'ACTIVE', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexDistributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadHoldIndexDistributionByPositionType(positionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} clientId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadPaymentsByClientId(clientId: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponsePaymentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadPaymentsByClientId(clientId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} clientId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {'APPROVED' | 'PENDING' | 'DECLINED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadPayoutsByClientIdAndStatus(clientId: number, page?: number, size?: number, sort?: Array<string>, status?: 'APPROVED' | 'PENDING' | 'DECLINED', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponsePayoutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadPayoutsByClientIdAndStatus(clientId, page, size, sort, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadProfileByClientId(clientId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadProfileByClientId(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadShortIndexDistribution(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexDistributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadShortIndexDistribution(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadTotalInvestedByClientId(clientId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalBalanceStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadTotalInvestedByClientId(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadTotalReferralsByClientId(clientId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadTotalReferralsByClientId(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reinvestHistory(userId: number, page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponseBonusReinvestRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reinvestHistory(userId, page, size, sort, localDateFrom, localDateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoadClientDashboardRestAdapterApi - factory interface
 * @export
 */
export const LoadClientDashboardRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoadClientDashboardRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAnnualInvestedByClientId(clientId: number, options?: any): AxiosPromise<TotalInvestedPerYearResponse> {
            return localVarFp.loadAnnualInvestedByClientId(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAvailableFunds(userId: number, options?: any): AxiosPromise<ClientAvailableFundsResponse> {
            return localVarFp.loadAvailableFunds(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBalanceHistory(id: number, from?: string, to?: string, options?: any): AxiosPromise<{ [key: string]: CurrentBalanceHistoryDataPoint; }> {
            return localVarFp.loadBalanceHistory(id, from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBalanceHistoryByTrades(id: number, options?: any): AxiosPromise<{ [key: string]: { [key: string]: PaymentHistoryDataPoint; }; }> {
            return localVarFp.loadBalanceHistoryByTrades(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBonusIncomeByClientId(clientId: number, options?: any): AxiosPromise<TotalStatisticPerMonthResponse> {
            return localVarFp.loadBonusIncomeByClientId(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {string} [affiliateName] 
         * @param {string} [countryCode] 
         * @param {'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBonusIncomeHistory1(userId: number, page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, affiliateName?: string, countryCode?: string, type?: 'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND', options?: any): AxiosPromise<AbstractPageableResponseBonusIncomeRecord> {
            return localVarFp.loadBonusIncomeHistory1(userId, page, size, sort, localDateFrom, localDateTo, affiliateName, countryCode, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadClientNotesByClientId(clientId: number, options?: any): AxiosPromise<Array<ClientNoteDTO>> {
            return localVarFp.loadClientNotesByClientId(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCurrentBalance(id: number, options?: any): AxiosPromise<CurrentBalanceHistoryDataPoint> {
            return localVarFp.loadCurrentBalance(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'ESTABLISHED' | 'STARTUP' | 'ACTIVE'} positionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadHoldIndexDistributionByPositionType(positionType: 'ESTABLISHED' | 'STARTUP' | 'ACTIVE', options?: any): AxiosPromise<IndexDistributionResponse> {
            return localVarFp.loadHoldIndexDistributionByPositionType(positionType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} clientId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadPaymentsByClientId(clientId: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<AbstractPageableResponsePaymentDTO> {
            return localVarFp.loadPaymentsByClientId(clientId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} clientId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {'APPROVED' | 'PENDING' | 'DECLINED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadPayoutsByClientIdAndStatus(clientId: number, page?: number, size?: number, sort?: Array<string>, status?: 'APPROVED' | 'PENDING' | 'DECLINED', options?: any): AxiosPromise<AbstractPageableResponsePayoutDTO> {
            return localVarFp.loadPayoutsByClientIdAndStatus(clientId, page, size, sort, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadProfileByClientId(clientId: number, options?: any): AxiosPromise<UserDTO> {
            return localVarFp.loadProfileByClientId(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadShortIndexDistribution(options?: any): AxiosPromise<IndexDistributionResponse> {
            return localVarFp.loadShortIndexDistribution(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTotalInvestedByClientId(clientId: number, options?: any): AxiosPromise<TotalBalanceStatusResponse> {
            return localVarFp.loadTotalInvestedByClientId(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTotalReferralsByClientId(clientId: number, options?: any): AxiosPromise<number> {
            return localVarFp.loadTotalReferralsByClientId(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reinvestHistory(userId: number, page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, options?: any): AxiosPromise<AbstractPageableResponseBonusReinvestRecord> {
            return localVarFp.reinvestHistory(userId, page, size, sort, localDateFrom, localDateTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for loadAnnualInvestedByClientId operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadAnnualInvestedByClientIdRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadAnnualInvestedByClientIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadAnnualInvestedByClientId
     */
    readonly clientId: number
}

/**
 * Request parameters for loadAvailableFunds operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadAvailableFundsRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadAvailableFundsRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadAvailableFunds
     */
    readonly userId: number
}

/**
 * Request parameters for loadBalanceHistory operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadBalanceHistoryRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadBalanceHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadBalanceHistory
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof LoadClientDashboardRestAdapterApiLoadBalanceHistory
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof LoadClientDashboardRestAdapterApiLoadBalanceHistory
     */
    readonly to?: string
}

/**
 * Request parameters for loadBalanceHistoryByTrades operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadBalanceHistoryByTradesRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadBalanceHistoryByTradesRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadBalanceHistoryByTrades
     */
    readonly id: number
}

/**
 * Request parameters for loadBonusIncomeByClientId operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadBonusIncomeByClientIdRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadBonusIncomeByClientIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadBonusIncomeByClientId
     */
    readonly clientId: number
}

/**
 * Request parameters for loadBonusIncomeHistory1 operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1Request
 */
export interface LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1Request {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1
     */
    readonly userId: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1
     */
    readonly localDateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1
     */
    readonly localDateTo?: string

    /**
     * 
     * @type {string}
     * @memberof LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1
     */
    readonly affiliateName?: string

    /**
     * 
     * @type {string}
     * @memberof LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1
     */
    readonly countryCode?: string

    /**
     * 
     * @type {'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'}
     * @memberof LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1
     */
    readonly type?: 'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'
}

/**
 * Request parameters for loadClientNotesByClientId operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadClientNotesByClientIdRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadClientNotesByClientIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadClientNotesByClientId
     */
    readonly clientId: number
}

/**
 * Request parameters for loadCurrentBalance operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadCurrentBalanceRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadCurrentBalanceRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadCurrentBalance
     */
    readonly id: number
}

/**
 * Request parameters for loadHoldIndexDistributionByPositionType operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadHoldIndexDistributionByPositionTypeRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadHoldIndexDistributionByPositionTypeRequest {
    /**
     * 
     * @type {'ESTABLISHED' | 'STARTUP' | 'ACTIVE'}
     * @memberof LoadClientDashboardRestAdapterApiLoadHoldIndexDistributionByPositionType
     */
    readonly positionType: 'ESTABLISHED' | 'STARTUP' | 'ACTIVE'
}

/**
 * Request parameters for loadPaymentsByClientId operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadPaymentsByClientIdRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadPaymentsByClientIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadPaymentsByClientId
     */
    readonly clientId: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadPaymentsByClientId
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadPaymentsByClientId
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoadClientDashboardRestAdapterApiLoadPaymentsByClientId
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for loadPayoutsByClientIdAndStatus operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatusRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatusRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatus
     */
    readonly clientId: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatus
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatus
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatus
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {'APPROVED' | 'PENDING' | 'DECLINED'}
     * @memberof LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatus
     */
    readonly status?: 'APPROVED' | 'PENDING' | 'DECLINED'
}

/**
 * Request parameters for loadProfileByClientId operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadProfileByClientIdRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadProfileByClientIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadProfileByClientId
     */
    readonly clientId: number
}

/**
 * Request parameters for loadTotalInvestedByClientId operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadTotalInvestedByClientIdRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadTotalInvestedByClientIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadTotalInvestedByClientId
     */
    readonly clientId: number
}

/**
 * Request parameters for loadTotalReferralsByClientId operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadTotalReferralsByClientIdRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadTotalReferralsByClientIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadTotalReferralsByClientId
     */
    readonly clientId: number
}

/**
 * Request parameters for reinvestHistory operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiReinvestHistoryRequest
 */
export interface LoadClientDashboardRestAdapterApiReinvestHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiReinvestHistory
     */
    readonly userId: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiReinvestHistory
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiReinvestHistory
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoadClientDashboardRestAdapterApiReinvestHistory
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof LoadClientDashboardRestAdapterApiReinvestHistory
     */
    readonly localDateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof LoadClientDashboardRestAdapterApiReinvestHistory
     */
    readonly localDateTo?: string
}

/**
 * LoadClientDashboardRestAdapterApi - object-oriented interface
 * @export
 * @class LoadClientDashboardRestAdapterApi
 * @extends {BaseAPI}
 */
export class LoadClientDashboardRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadAnnualInvestedByClientIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadAnnualInvestedByClientId(requestParameters: LoadClientDashboardRestAdapterApiLoadAnnualInvestedByClientIdRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadAnnualInvestedByClientId(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadAvailableFundsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadAvailableFunds(requestParameters: LoadClientDashboardRestAdapterApiLoadAvailableFundsRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadAvailableFunds(requestParameters.userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadBalanceHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadBalanceHistory(requestParameters: LoadClientDashboardRestAdapterApiLoadBalanceHistoryRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadBalanceHistory(requestParameters.id, requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadBalanceHistoryByTradesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadBalanceHistoryByTrades(requestParameters: LoadClientDashboardRestAdapterApiLoadBalanceHistoryByTradesRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadBalanceHistoryByTrades(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadBonusIncomeByClientIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadBonusIncomeByClientId(requestParameters: LoadClientDashboardRestAdapterApiLoadBonusIncomeByClientIdRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadBonusIncomeByClientId(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadBonusIncomeHistory1(requestParameters: LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1Request, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadBonusIncomeHistory1(requestParameters.userId, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.localDateFrom, requestParameters.localDateTo, requestParameters.affiliateName, requestParameters.countryCode, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadClientNotesByClientIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadClientNotesByClientId(requestParameters: LoadClientDashboardRestAdapterApiLoadClientNotesByClientIdRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadClientNotesByClientId(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadCurrentBalanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadCurrentBalance(requestParameters: LoadClientDashboardRestAdapterApiLoadCurrentBalanceRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadCurrentBalance(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadHoldIndexDistributionByPositionTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadHoldIndexDistributionByPositionType(requestParameters: LoadClientDashboardRestAdapterApiLoadHoldIndexDistributionByPositionTypeRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadHoldIndexDistributionByPositionType(requestParameters.positionType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadPaymentsByClientIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadPaymentsByClientId(requestParameters: LoadClientDashboardRestAdapterApiLoadPaymentsByClientIdRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadPaymentsByClientId(requestParameters.clientId, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadPayoutsByClientIdAndStatus(requestParameters: LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatusRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadPayoutsByClientIdAndStatus(requestParameters.clientId, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadProfileByClientIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadProfileByClientId(requestParameters: LoadClientDashboardRestAdapterApiLoadProfileByClientIdRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadProfileByClientId(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadShortIndexDistribution(options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadShortIndexDistribution(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadTotalInvestedByClientIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadTotalInvestedByClientId(requestParameters: LoadClientDashboardRestAdapterApiLoadTotalInvestedByClientIdRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadTotalInvestedByClientId(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadTotalReferralsByClientIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadTotalReferralsByClientId(requestParameters: LoadClientDashboardRestAdapterApiLoadTotalReferralsByClientIdRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadTotalReferralsByClientId(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiReinvestHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public reinvestHistory(requestParameters: LoadClientDashboardRestAdapterApiReinvestHistoryRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).reinvestHistory(requestParameters.userId, requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.localDateFrom, requestParameters.localDateTo, options).then((request) => request(this.axios, this.basePath));
    }
}

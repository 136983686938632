/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AbstractPageableResponseVipIndexActivityDTO } from '../model';
// @ts-ignore
import { AbstractPageableResponseVipIndexTransactionDTO } from '../model';
// @ts-ignore
import { CryptoDataResponse } from '../model';
// @ts-ignore
import { VipIndexDTO } from '../model';
/**
 * LoadClientVipIndexRestAdapterApi - axios parameter creator
 * @export
 */
export const LoadClientVipIndexRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'INVESTED' | 'WITHDRAWN'} type 
         * @param {number} clientId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listByType: async (type: 'INVESTED' | 'WITHDRAWN', clientId: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('listByType', 'type', type)
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('listByType', 'clientId', clientId)
            const localVarPath = `/client-vip-index/load-activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCryptoData: async (clientId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('loadCryptoData', 'clientId', clientId)
            const localVarPath = `/client-vip-index/load-crypto-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadVipIndexList: async (clientId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('loadVipIndexList', 'clientId', clientId)
            const localVarPath = `/client-vip-index/load-vip-index-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} clientId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadVipIndexTransactionList: async (clientId: number, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientId' is not null or undefined
            assertParamExists('loadVipIndexTransactionList', 'clientId', clientId)
            const localVarPath = `/client-vip-index/load-vip-index-transaction-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (clientId !== undefined) {
                localVarQueryParameter['clientId'] = clientId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoadClientVipIndexRestAdapterApi - functional programming interface
 * @export
 */
export const LoadClientVipIndexRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoadClientVipIndexRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'INVESTED' | 'WITHDRAWN'} type 
         * @param {number} clientId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listByType(type: 'INVESTED' | 'WITHDRAWN', clientId: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponseVipIndexActivityDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listByType(type, clientId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadCryptoData(clientId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CryptoDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadCryptoData(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadVipIndexList(clientId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VipIndexDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadVipIndexList(clientId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} clientId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadVipIndexTransactionList(clientId: number, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponseVipIndexTransactionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadVipIndexTransactionList(clientId, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoadClientVipIndexRestAdapterApi - factory interface
 * @export
 */
export const LoadClientVipIndexRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoadClientVipIndexRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {'INVESTED' | 'WITHDRAWN'} type 
         * @param {number} clientId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listByType(type: 'INVESTED' | 'WITHDRAWN', clientId: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<AbstractPageableResponseVipIndexActivityDTO> {
            return localVarFp.listByType(type, clientId, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCryptoData(clientId: number, options?: any): AxiosPromise<CryptoDataResponse> {
            return localVarFp.loadCryptoData(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} clientId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadVipIndexList(clientId: number, options?: any): AxiosPromise<Array<VipIndexDTO>> {
            return localVarFp.loadVipIndexList(clientId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} clientId 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadVipIndexTransactionList(clientId: number, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<AbstractPageableResponseVipIndexTransactionDTO> {
            return localVarFp.loadVipIndexTransactionList(clientId, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for listByType operation in LoadClientVipIndexRestAdapterApi.
 * @export
 * @interface LoadClientVipIndexRestAdapterApiListByTypeRequest
 */
export interface LoadClientVipIndexRestAdapterApiListByTypeRequest {
    /**
     * 
     * @type {'INVESTED' | 'WITHDRAWN'}
     * @memberof LoadClientVipIndexRestAdapterApiListByType
     */
    readonly type: 'INVESTED' | 'WITHDRAWN'

    /**
     * 
     * @type {number}
     * @memberof LoadClientVipIndexRestAdapterApiListByType
     */
    readonly clientId: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoadClientVipIndexRestAdapterApiListByType
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoadClientVipIndexRestAdapterApiListByType
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoadClientVipIndexRestAdapterApiListByType
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for loadCryptoData operation in LoadClientVipIndexRestAdapterApi.
 * @export
 * @interface LoadClientVipIndexRestAdapterApiLoadCryptoDataRequest
 */
export interface LoadClientVipIndexRestAdapterApiLoadCryptoDataRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientVipIndexRestAdapterApiLoadCryptoData
     */
    readonly clientId: number
}

/**
 * Request parameters for loadVipIndexList operation in LoadClientVipIndexRestAdapterApi.
 * @export
 * @interface LoadClientVipIndexRestAdapterApiLoadVipIndexListRequest
 */
export interface LoadClientVipIndexRestAdapterApiLoadVipIndexListRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientVipIndexRestAdapterApiLoadVipIndexList
     */
    readonly clientId: number
}

/**
 * Request parameters for loadVipIndexTransactionList operation in LoadClientVipIndexRestAdapterApi.
 * @export
 * @interface LoadClientVipIndexRestAdapterApiLoadVipIndexTransactionListRequest
 */
export interface LoadClientVipIndexRestAdapterApiLoadVipIndexTransactionListRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientVipIndexRestAdapterApiLoadVipIndexTransactionList
     */
    readonly clientId: number

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoadClientVipIndexRestAdapterApiLoadVipIndexTransactionList
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoadClientVipIndexRestAdapterApiLoadVipIndexTransactionList
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoadClientVipIndexRestAdapterApiLoadVipIndexTransactionList
     */
    readonly sort?: Array<string>
}

/**
 * LoadClientVipIndexRestAdapterApi - object-oriented interface
 * @export
 * @class LoadClientVipIndexRestAdapterApi
 * @extends {BaseAPI}
 */
export class LoadClientVipIndexRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {LoadClientVipIndexRestAdapterApiListByTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientVipIndexRestAdapterApi
     */
    public listByType(requestParameters: LoadClientVipIndexRestAdapterApiListByTypeRequest, options?: any) {
        return LoadClientVipIndexRestAdapterApiFp(this.configuration).listByType(requestParameters.type, requestParameters.clientId, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientVipIndexRestAdapterApiLoadCryptoDataRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientVipIndexRestAdapterApi
     */
    public loadCryptoData(requestParameters: LoadClientVipIndexRestAdapterApiLoadCryptoDataRequest, options?: any) {
        return LoadClientVipIndexRestAdapterApiFp(this.configuration).loadCryptoData(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientVipIndexRestAdapterApiLoadVipIndexListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientVipIndexRestAdapterApi
     */
    public loadVipIndexList(requestParameters: LoadClientVipIndexRestAdapterApiLoadVipIndexListRequest, options?: any) {
        return LoadClientVipIndexRestAdapterApiFp(this.configuration).loadVipIndexList(requestParameters.clientId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientVipIndexRestAdapterApiLoadVipIndexTransactionListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientVipIndexRestAdapterApi
     */
    public loadVipIndexTransactionList(requestParameters: LoadClientVipIndexRestAdapterApiLoadVipIndexTransactionListRequest, options?: any) {
        return LoadClientVipIndexRestAdapterApiFp(this.configuration).loadVipIndexTransactionList(requestParameters.clientId, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }
}

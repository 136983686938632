// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import Settings from './components/settings';
import RtlLayout from './components/RtlLayout';
import { ChartStyle } from './components/chart';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import { ProgressBarStyle } from './components/ProgressBar';
import NotistackProvider from './components/NotistackProvider';
import ThemeColorPresets from './components/ThemeColorPresets';
import ThemeLocalization from './components/ThemeLocalization';
import MotionLazyContainer from './components/animate/MotionLazyContainer';
import CustomCookieConsent from './components/CustomCoockieConsent';
import { LocalizationProvider } from '@mui/lab';
import { BrowserRouter } from 'react-router-dom';
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { NotificationProvider } from './contexts/NotificationContext';
import { SettingsProvider } from './contexts/SettingsContext';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { sk, enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function App() {
  const { i18n } = useTranslation();
  const locale = i18n.language === 'sk' ? sk : enUS;

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={locale}>
      <SettingsProvider>
        <CollapseDrawerProvider>
          <BrowserRouter>
            <NotificationProvider>
              <ThemeProvider>
                <ThemeColorPresets>
                  <ThemeLocalization>
                    <RtlLayout>
                      <NotistackProvider>
                        <MotionLazyContainer>
                          <GlobalStyles />
                          <ProgressBarStyle />
                          <ChartStyle />
                          {/* <Settings /> */}
                          <ScrollToTop />
                          <GoogleAnalytics />
                          <Router />
                          <CustomCookieConsent />
                        </MotionLazyContainer>
                      </NotistackProvider>
                    </RtlLayout>
                  </ThemeLocalization>
                </ThemeColorPresets>
              </ThemeProvider>
            </NotificationProvider>
          </BrowserRouter>
        </CollapseDrawerProvider>
      </SettingsProvider>
    </LocalizationProvider>
  );
}

import { Box, Button, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
// assets
import { SentIcon } from 'src/assets';
// routes
import { PATH_AUTH } from 'src/routes/paths';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

type PropsType = {
  email: string;
};

export default function RegisterFinishStep({ email }: PropsType) {
  const { t } = useTranslation();
  return (
    <Box sx={{ textAlign: 'center' }}>
      <SentIcon sx={{ mb: 5, mx: 'auto', height: 160 }} />

      <Typography variant="h3" gutterBottom>
        {t('registerFinishStep.registerUserSuccessfully')}
      </Typography>
      <Typography>
        {t('registerFinishStep.weHaveSentConfirmation')} &nbsp;
        <strong>{email}</strong>
        <br />
        {t('resetPassword.pleaseCheckYourEmail')}
      </Typography>

      <Button
        size="large"
        variant="contained"
        component={RouterLink}
        to={PATH_AUTH.login}
        sx={{ mt: 5 }}
      >
        {t('newPassword.goToLogin')}
      </Button>
    </Box>
  );
}

/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClientNoteCreateRequest } from '../model';
// @ts-ignore
import { ClientNoteUpdateRequest } from '../model';
/**
 * ManageClientNotesRestAdapterApi - axios parameter creator
 * @export
 */
export const ManageClientNotesRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ClientNoteCreateRequest} clientNoteCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNew: async (clientNoteCreateRequest: ClientNoteCreateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'clientNoteCreateRequest' is not null or undefined
            assertParamExists('createNew', 'clientNoteCreateRequest', clientNoteCreateRequest)
            const localVarPath = `/notes/create-new`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientNoteCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteById', 'id', id)
            const localVarPath = `/notes/delete/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientNoteUpdateRequest} clientNoteUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateById: async (id: number, clientNoteUpdateRequest: ClientNoteUpdateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateById', 'id', id)
            // verify required parameter 'clientNoteUpdateRequest' is not null or undefined
            assertParamExists('updateById', 'clientNoteUpdateRequest', clientNoteUpdateRequest)
            const localVarPath = `/notes/update/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(clientNoteUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManageClientNotesRestAdapterApi - functional programming interface
 * @export
 */
export const ManageClientNotesRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManageClientNotesRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ClientNoteCreateRequest} clientNoteCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createNew(clientNoteCreateRequest: ClientNoteCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createNew(clientNoteCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientNoteUpdateRequest} clientNoteUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateById(id: number, clientNoteUpdateRequest: ClientNoteUpdateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateById(id, clientNoteUpdateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManageClientNotesRestAdapterApi - factory interface
 * @export
 */
export const ManageClientNotesRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManageClientNotesRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {ClientNoteCreateRequest} clientNoteCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createNew(clientNoteCreateRequest: ClientNoteCreateRequest, options?: any): AxiosPromise<object> {
            return localVarFp.createNew(clientNoteCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteById(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.deleteById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {ClientNoteUpdateRequest} clientNoteUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateById(id: number, clientNoteUpdateRequest: ClientNoteUpdateRequest, options?: any): AxiosPromise<object> {
            return localVarFp.updateById(id, clientNoteUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createNew operation in ManageClientNotesRestAdapterApi.
 * @export
 * @interface ManageClientNotesRestAdapterApiCreateNewRequest
 */
export interface ManageClientNotesRestAdapterApiCreateNewRequest {
    /**
     * 
     * @type {ClientNoteCreateRequest}
     * @memberof ManageClientNotesRestAdapterApiCreateNew
     */
    readonly clientNoteCreateRequest: ClientNoteCreateRequest
}

/**
 * Request parameters for deleteById operation in ManageClientNotesRestAdapterApi.
 * @export
 * @interface ManageClientNotesRestAdapterApiDeleteByIdRequest
 */
export interface ManageClientNotesRestAdapterApiDeleteByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ManageClientNotesRestAdapterApiDeleteById
     */
    readonly id: number
}

/**
 * Request parameters for updateById operation in ManageClientNotesRestAdapterApi.
 * @export
 * @interface ManageClientNotesRestAdapterApiUpdateByIdRequest
 */
export interface ManageClientNotesRestAdapterApiUpdateByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ManageClientNotesRestAdapterApiUpdateById
     */
    readonly id: number

    /**
     * 
     * @type {ClientNoteUpdateRequest}
     * @memberof ManageClientNotesRestAdapterApiUpdateById
     */
    readonly clientNoteUpdateRequest: ClientNoteUpdateRequest
}

/**
 * ManageClientNotesRestAdapterApi - object-oriented interface
 * @export
 * @class ManageClientNotesRestAdapterApi
 * @extends {BaseAPI}
 */
export class ManageClientNotesRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {ManageClientNotesRestAdapterApiCreateNewRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageClientNotesRestAdapterApi
     */
    public createNew(requestParameters: ManageClientNotesRestAdapterApiCreateNewRequest, options?: any) {
        return ManageClientNotesRestAdapterApiFp(this.configuration).createNew(requestParameters.clientNoteCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManageClientNotesRestAdapterApiDeleteByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageClientNotesRestAdapterApi
     */
    public deleteById(requestParameters: ManageClientNotesRestAdapterApiDeleteByIdRequest, options?: any) {
        return ManageClientNotesRestAdapterApiFp(this.configuration).deleteById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManageClientNotesRestAdapterApiUpdateByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageClientNotesRestAdapterApi
     */
    public updateById(requestParameters: ManageClientNotesRestAdapterApiUpdateByIdRequest, options?: any) {
        return ManageClientNotesRestAdapterApiFp(this.configuration).updateById(requestParameters.id, requestParameters.clientNoteUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

import { getAuthConfiguration } from './auth';
import {
  ClientNoteDTO,
  CurrentBalanceHistoryDataPoint,
  IndexDistribution,
  LoadClientDashboardRestAdapterApi,
  LoadClientDashboardRestAdapterApiLoadAnnualInvestedByClientIdRequest,
  LoadClientDashboardRestAdapterApiLoadBalanceHistoryByTradesRequest,
  LoadClientDashboardRestAdapterApiLoadBalanceHistoryRequest,
  LoadClientDashboardRestAdapterApiLoadBonusIncomeByClientIdRequest,
  LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1Request,
  LoadClientDashboardRestAdapterApiLoadClientNotesByClientIdRequest,
  LoadClientDashboardRestAdapterApiLoadCurrentBalanceRequest,
  LoadClientDashboardRestAdapterApiLoadHoldIndexDistributionByPositionTypeRequest,
  LoadClientDashboardRestAdapterApiLoadPaymentsByClientIdRequest,
  LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatusRequest,
  LoadClientDashboardRestAdapterApiLoadProfileByClientIdRequest,
  LoadClientDashboardRestAdapterApiLoadTotalInvestedByClientIdRequest,
  LoadClientDashboardRestAdapterApiLoadTotalReferralsByClientIdRequest,
  PageBonusIncomeRecord,
  PagePaymentDTO,
  PagePayoutDTO,
  PaymentDTO,
  PaymentHistoryDataPoint,
  PayoutDTO,
  TotalBalanceStatusResponse,
  TotalInvestedPerYearResponse,
  TotalStatisticPerMonthResponse,
  UserDTO,
} from './generated/partner';

export type BalanceHistoryType = {
  [key: string]: CurrentBalanceHistoryDataPoint;
};

export type BalanceHistoryTypeByTrade = {
  [key: string]: {
    [key: string]: PaymentHistoryDataPoint;
  };
};

export const getClientTotalReferrals = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadTotalReferralsByClientIdRequest
): Promise<number> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadTotalReferralsByClientId(requestParameters)).data;
};

export const getClientTotalInvested = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadTotalInvestedByClientIdRequest
): Promise<TotalBalanceStatusResponse> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadTotalInvestedByClientId(requestParameters)).data;
};

export const getClientShortIndexDistribution = async (): Promise<Array<IndexDistribution>> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadShortIndexDistribution()).data.indexDistribution;
};

export const getClientProfile = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadProfileByClientIdRequest
): Promise<UserDTO> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadProfileByClientId(requestParameters)).data;
};

export const getClientPayouts = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatusRequest
): Promise<PagePayoutDTO> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadPayoutsByClientIdAndStatus(requestParameters)).data.pagedData;
};

export const getClientPayments = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadPaymentsByClientIdRequest
): Promise<PagePaymentDTO> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadPaymentsByClientId(requestParameters)).data.pagedData;
};

export const getClientHoldIndexDistribution = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadHoldIndexDistributionByPositionTypeRequest
): Promise<Array<IndexDistribution>> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadHoldIndexDistributionByPositionType(requestParameters)).data
    .indexDistribution;
};

export const getClientCurrentBalance = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadCurrentBalanceRequest
): Promise<CurrentBalanceHistoryDataPoint> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadCurrentBalance(requestParameters)).data;
};

export const getClientNotes = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadClientNotesByClientIdRequest
): Promise<Array<ClientNoteDTO>> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadClientNotesByClientId(requestParameters)).data;
};

export const getClientProfitsFromReferrals = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadBonusIncomeByClientIdRequest
): Promise<TotalStatisticPerMonthResponse> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadBonusIncomeByClientId(requestParameters)).data;
};

export const getClientBalanceHistory = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadBalanceHistoryRequest
): Promise<BalanceHistoryType> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadBalanceHistory(requestParameters)).data;
};

export const getClientBalanceHistoryByTrade = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadBalanceHistoryByTradesRequest
): Promise<BalanceHistoryTypeByTrade> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadBalanceHistoryByTrades(requestParameters)).data;
};

export const getAnnalInvested = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadAnnualInvestedByClientIdRequest
): Promise<TotalInvestedPerYearResponse> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadAnnualInvestedByClientId(requestParameters)).data;
};

export const loadClientBonusIncome = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadBonusIncomeHistory1Request
): Promise<PageBonusIncomeRecord> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadBonusIncomeHistory1(requestParameters)).data.pagedData;
};


import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getNewsList } from 'src/api';

type NotificationProviderPropsType = {
  children: ReactNode | ReactNode[];
};

const NotificationContextDefaultValue = {
  notificationCount: 0,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setNotificationCount: (count: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  loadCount: () => {},
};

const NotificationContext = React.createContext(NotificationContextDefaultValue);
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useNotification = () => useContext(NotificationContext);

export const NotificationProvider: React.FC<NotificationProviderPropsType> = ({ children }) => {
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const { i18n } = useTranslation();
  const language = useMemo(() => (i18n.language === 'en' ? 'EN' : 'SK'), [i18n.language]);
  const loadCount = useCallback(async () => {
    try {
      const response = await getNewsList({ fetchInbox: false, language });
      setNotificationCount(response.unreadCount);
    } catch {}
  }, [language]);

  useEffect(() => {
    loadCount();
  }, [loadCount]);

  return (
    <NotificationContext.Provider
      value={{
        notificationCount,
        setNotificationCount,
        loadCount,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

import { getAuthConfiguration } from './auth';
import {
  CryptoDataResponse,
  LoadClientVipIndexRestAdapterApi,
  LoadClientVipIndexRestAdapterApiListByTypeRequest,
  LoadClientVipIndexRestAdapterApiLoadCryptoDataRequest,
  LoadClientVipIndexRestAdapterApiLoadVipIndexTransactionListRequest,
  PageVipIndexActivityDTO,
  PageVipIndexTransactionDTO,
  VipIndexDTO,
} from './generated/partner';

export const loadVipIndexList = async (
  requestParameters: LoadClientVipIndexRestAdapterApiLoadVipIndexTransactionListRequest
): Promise<Array<VipIndexDTO>> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientVipIndexRestAdapterApi(conf);

  return (await api.loadVipIndexList(requestParameters)).data;
};

export const loadVipIndexTransactionList = async (
  requestParameters: LoadClientVipIndexRestAdapterApiLoadVipIndexTransactionListRequest
): Promise<PageVipIndexTransactionDTO> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientVipIndexRestAdapterApi(conf);

  return (await api.loadVipIndexTransactionList(requestParameters)).data.pagedData;
};

export const loadCryptoData = async (
  requestParameters: LoadClientVipIndexRestAdapterApiLoadCryptoDataRequest
): Promise<CryptoDataResponse> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientVipIndexRestAdapterApi(conf);

  return (await api.loadCryptoData(requestParameters)).data;
};

export const loadVipIndexActivity = async (
  requestParameters: LoadClientVipIndexRestAdapterApiListByTypeRequest
): Promise<PageVipIndexActivityDTO> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientVipIndexRestAdapterApi(conf);

  return (await api.listByType(requestParameters)).data.pagedData;
};

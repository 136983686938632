import { getAuthConfiguration } from './auth';
import {
  InboxMessageDTO,
  InboxResponse,
  ManageInboxMessagesRestAdapterApi,
  ManageInboxMessagesRestAdapterApiGetContentRequest,
  ManageInboxMessagesRestAdapterApiLikeInboxByIdRequest,
  ManageInboxMessagesRestAdapterApiListRequest,
  ManageInboxMessagesRestAdapterApiReadInboxByIdRequest,
} from './generated/partner';

export const getNewsList = async (
  requestParameters: ManageInboxMessagesRestAdapterApiListRequest
): Promise<InboxResponse> => {
  const conf = getAuthConfiguration();
  const api = new ManageInboxMessagesRestAdapterApi(conf);

  return (await api.list(requestParameters)).data;
};

export const readNews = async (
  requestParameters: ManageInboxMessagesRestAdapterApiReadInboxByIdRequest
): Promise<object> => {
  const conf = getAuthConfiguration();
  const api = new ManageInboxMessagesRestAdapterApi(conf);

  return (await api.readInboxById(requestParameters)).data;
};

export const likeNews = async (
  requestParameters: ManageInboxMessagesRestAdapterApiLikeInboxByIdRequest
): Promise<object> => {
  const conf = getAuthConfiguration();
  const api = new ManageInboxMessagesRestAdapterApi(conf);

  return (await api.likeInboxById(requestParameters)).data;
};

export const getNewsContent = async (
  requestParameters: ManageInboxMessagesRestAdapterApiGetContentRequest
): Promise<InboxMessageDTO> => {
  const conf = getAuthConfiguration();
  const api = new ManageInboxMessagesRestAdapterApi(conf);

  return (await api.getContent(requestParameters)).data;
};

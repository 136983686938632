/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InboxMessageDTO } from '../model';
// @ts-ignore
import { InboxResponse } from '../model';
/**
 * ManageInboxMessagesRestAdapterApi - axios parameter creator
 * @export
 */
export const ManageInboxMessagesRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContent: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getContent', 'id', id)
            const localVarPath = `/inbox/get-content`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeInboxById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('likeInboxById', 'id', id)
            const localVarPath = `/inbox/like-inbox/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'EN' | 'SK'} [language] 
         * @param {'MARKETING' | 'NEWS' | 'INFORMATION' | 'WEBINAR'} [type] 
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {boolean} [fetchInbox] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list: async (language?: 'EN' | 'SK', type?: 'MARKETING' | 'NEWS' | 'INFORMATION' | 'WEBINAR', localDateFrom?: string, localDateTo?: string, fetchInbox?: boolean, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/inbox/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (language !== undefined) {
                localVarQueryParameter['language'] = language;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (localDateFrom !== undefined) {
                localVarQueryParameter['localDateFrom'] = (localDateFrom as any instanceof Date) ?
                    (localDateFrom as any).toISOString().substr(0,10) :
                    localDateFrom;
            }

            if (localDateTo !== undefined) {
                localVarQueryParameter['localDateTo'] = (localDateTo as any instanceof Date) ?
                    (localDateTo as any).toISOString().substr(0,10) :
                    localDateTo;
            }

            if (fetchInbox !== undefined) {
                localVarQueryParameter['fetchInbox'] = fetchInbox;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInboxById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('readInboxById', 'id', id)
            const localVarPath = `/inbox/read-inbox/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManageInboxMessagesRestAdapterApi - functional programming interface
 * @export
 */
export const ManageInboxMessagesRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManageInboxMessagesRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContent(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxMessageDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContent(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async likeInboxById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.likeInboxById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'EN' | 'SK'} [language] 
         * @param {'MARKETING' | 'NEWS' | 'INFORMATION' | 'WEBINAR'} [type] 
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {boolean} [fetchInbox] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list(language?: 'EN' | 'SK', type?: 'MARKETING' | 'NEWS' | 'INFORMATION' | 'WEBINAR', localDateFrom?: string, localDateTo?: string, fetchInbox?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InboxResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list(language, type, localDateFrom, localDateTo, fetchInbox, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async readInboxById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.readInboxById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManageInboxMessagesRestAdapterApi - factory interface
 * @export
 */
export const ManageInboxMessagesRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManageInboxMessagesRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContent(id: number, options?: any): AxiosPromise<InboxMessageDTO> {
            return localVarFp.getContent(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeInboxById(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.likeInboxById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'EN' | 'SK'} [language] 
         * @param {'MARKETING' | 'NEWS' | 'INFORMATION' | 'WEBINAR'} [type] 
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {boolean} [fetchInbox] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list(language?: 'EN' | 'SK', type?: 'MARKETING' | 'NEWS' | 'INFORMATION' | 'WEBINAR', localDateFrom?: string, localDateTo?: string, fetchInbox?: boolean, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<InboxResponse> {
            return localVarFp.list(language, type, localDateFrom, localDateTo, fetchInbox, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        readInboxById(id: number, options?: any): AxiosPromise<object> {
            return localVarFp.readInboxById(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getContent operation in ManageInboxMessagesRestAdapterApi.
 * @export
 * @interface ManageInboxMessagesRestAdapterApiGetContentRequest
 */
export interface ManageInboxMessagesRestAdapterApiGetContentRequest {
    /**
     * 
     * @type {number}
     * @memberof ManageInboxMessagesRestAdapterApiGetContent
     */
    readonly id: number
}

/**
 * Request parameters for likeInboxById operation in ManageInboxMessagesRestAdapterApi.
 * @export
 * @interface ManageInboxMessagesRestAdapterApiLikeInboxByIdRequest
 */
export interface ManageInboxMessagesRestAdapterApiLikeInboxByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ManageInboxMessagesRestAdapterApiLikeInboxById
     */
    readonly id: number
}

/**
 * Request parameters for list operation in ManageInboxMessagesRestAdapterApi.
 * @export
 * @interface ManageInboxMessagesRestAdapterApiListRequest
 */
export interface ManageInboxMessagesRestAdapterApiListRequest {
    /**
     * 
     * @type {'EN' | 'SK'}
     * @memberof ManageInboxMessagesRestAdapterApiList
     */
    readonly language?: 'EN' | 'SK'

    /**
     * 
     * @type {'MARKETING' | 'NEWS' | 'INFORMATION' | 'WEBINAR'}
     * @memberof ManageInboxMessagesRestAdapterApiList
     */
    readonly type?: 'MARKETING' | 'NEWS' | 'INFORMATION' | 'WEBINAR'

    /**
     * 
     * @type {string}
     * @memberof ManageInboxMessagesRestAdapterApiList
     */
    readonly localDateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof ManageInboxMessagesRestAdapterApiList
     */
    readonly localDateTo?: string

    /**
     * 
     * @type {boolean}
     * @memberof ManageInboxMessagesRestAdapterApiList
     */
    readonly fetchInbox?: boolean

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ManageInboxMessagesRestAdapterApiList
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ManageInboxMessagesRestAdapterApiList
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ManageInboxMessagesRestAdapterApiList
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for readInboxById operation in ManageInboxMessagesRestAdapterApi.
 * @export
 * @interface ManageInboxMessagesRestAdapterApiReadInboxByIdRequest
 */
export interface ManageInboxMessagesRestAdapterApiReadInboxByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof ManageInboxMessagesRestAdapterApiReadInboxById
     */
    readonly id: number
}

/**
 * ManageInboxMessagesRestAdapterApi - object-oriented interface
 * @export
 * @class ManageInboxMessagesRestAdapterApi
 * @extends {BaseAPI}
 */
export class ManageInboxMessagesRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {ManageInboxMessagesRestAdapterApiGetContentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageInboxMessagesRestAdapterApi
     */
    public getContent(requestParameters: ManageInboxMessagesRestAdapterApiGetContentRequest, options?: any) {
        return ManageInboxMessagesRestAdapterApiFp(this.configuration).getContent(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManageInboxMessagesRestAdapterApiLikeInboxByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageInboxMessagesRestAdapterApi
     */
    public likeInboxById(requestParameters: ManageInboxMessagesRestAdapterApiLikeInboxByIdRequest, options?: any) {
        return ManageInboxMessagesRestAdapterApiFp(this.configuration).likeInboxById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManageInboxMessagesRestAdapterApiListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageInboxMessagesRestAdapterApi
     */
    public list(requestParameters: ManageInboxMessagesRestAdapterApiListRequest = {}, options?: any) {
        return ManageInboxMessagesRestAdapterApiFp(this.configuration).list(requestParameters.language, requestParameters.type, requestParameters.localDateFrom, requestParameters.localDateTo, requestParameters.fetchInbox, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManageInboxMessagesRestAdapterApiReadInboxByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageInboxMessagesRestAdapterApi
     */
    public readInboxById(requestParameters: ManageInboxMessagesRestAdapterApiReadInboxByIdRequest, options?: any) {
        return ManageInboxMessagesRestAdapterApiFp(this.configuration).readInboxById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}

import { ElementType, lazy, Suspense } from 'react';
import { Navigate, useLocation, useRoutes } from 'react-router-dom';
// components
import LoadingScreen from '../components/LoadingScreen';
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// config
import { PATH_AFTER_LOGIN } from '../config';
import AuthGuard from '../guards/AuthGuard';
// guards
import GuestGuard from '../guards/GuestGuard';
// guards
import useAuth from '../hooks/useAuth';
import DashboardLayout from '../layouts/dashboard/index';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { isAuthenticated } = useAuth();

  const isDashboard = pathname.includes('/dashboard') && isAuthenticated;

  return (
    <Suspense fallback={<LoadingScreen isDashboard={isDashboard} />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: <Navigate to={PATH_AFTER_LOGIN} replace />,
      index: true,
    },
    {
      path: '/auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              <Login />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          ),
        },
        {
          path: 'register-confirm',
          element: (
            <GuestGuard>
              <RegisterConfirm />
            </GuestGuard>
          ),
        },
        { path: 'forgot-password', element: <ResetPassword /> },
        { path: 'reset-password', element: <NewPassword /> },
        { path: 'verify', element: <VerifyCode /> },
      ],
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          element: <Navigate to={PATH_AFTER_LOGIN} replace />,
          index: true,
        },
        {
          children: [{ path: 'main', element: <DashboardPage /> }],
        },
        {
          path: 'clients',
          children: [
            {
              element: <Navigate to="/dashboard/clients/list" replace />,
              index: true,
            },
            { path: 'list', element: <AffiliatePage /> },
            { path: ':clientId/dashboard', element: <ClientDashboardPage /> },
            { path: 'invite', element: <InviteUserPage /> },
          ],
        },
        {
          path: 'news',
          children: [
            {
              element: <Navigate to="/dashboard/news/posts" replace />,
              index: true,
            },
            { path: 'posts', element: <NewsPage /> },
            { path: 'posts/:postId/:title', element: <NewsDetailPage /> },
          ],
        },
        { path: 'news', element: <NewsPage /> },
        { path: 'settings', element: <DashboardPage /> },
      ],
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'coming-soon', element: <ComingSoon /> },
        { path: 'maintenance', element: <Maintenance /> },
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/auth/Login')));
const Register = Loadable(lazy(() => import('../pages/auth/Register')));
const RegisterConfirm = Loadable(lazy(() => import('../pages/auth/RegisterConfirm')));
const ResetPassword = Loadable(lazy(() => import('../pages/auth/ResetPassword')));
const NewPassword = Loadable(lazy(() => import('../pages/auth/NewPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/auth/VerifyCode')));
//Admin Dashboard
const DashboardPage = Loadable(lazy(() => import('../pages/dashboard/DashboardPage')));
const NewsPage = Loadable(lazy(() => import('../pages/dashboard/NewsPage')));
const NewsDetailPage = Loadable(lazy(() => import('../pages/dashboard/NewsDetailPage')));
const AffiliatePage = Loadable(lazy(() => import('../pages/dashboard/AffiliatePage')));
const InviteUserPage = Loadable(lazy(() => import('../pages/dashboard/InviteUserPage')));
const ClientDashboardPage = Loadable(lazy(() => import('../pages/dashboard/ClientDashboardPage')));
// const ProfilePage = Loadable(lazy(() => import('../pages/dashboard/ProfilePage')));

// Main
const ComingSoon = Loadable(lazy(() => import('../pages/ComingSoon')));
const Maintenance = Loadable(lazy(() => import('../pages/Maintenance')));
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));

import { getAuthConfiguration } from './auth';
import { CalculateBalanceRestAdapterApi } from './generated/common';
import {
  CurrentBalanceHistoryDataPoint,
  LoadMainDashboardRestAdapterApi,
  LoadMainDashboardRestAdapterApiLoadBonusIncomeHistoryRequest,
  LoadMainDashboardRestAdapterApiLoadIncomesRequest,
  LoadMainDashboardRestAdapterApiLoadTopReferralsRequest,
  LoadMainDashboardRestAdapterApiLoadWithdrawalsRequest,
  MonthlyResponse,
  PageBonusIncomeRecord,
  PaymentDTO,
  PaymentPayoutDTO,
  UserDTO,
} from './generated/partner';

export const loadWithdrawals = async (
  requestParameters: LoadMainDashboardRestAdapterApiLoadWithdrawalsRequest
): Promise<Array<PaymentPayoutDTO>> => {
  const conf = getAuthConfiguration();
  const api = new LoadMainDashboardRestAdapterApi(conf);

  return (await api.loadWithdrawals(requestParameters)).data;
};

export const loadTopReferrals = async (
  requestParameters: LoadMainDashboardRestAdapterApiLoadTopReferralsRequest
): Promise<Array<UserDTO>> => {
  const conf = getAuthConfiguration();
  const api = new LoadMainDashboardRestAdapterApi(conf);

  return (await api.loadTopReferrals(requestParameters)).data;
};

export const loadReferralsActivity = async (): Promise<MonthlyResponse> => {
  const conf = getAuthConfiguration();
  const api = new LoadMainDashboardRestAdapterApi(conf);

  return (await api.loadReferralsActivityMonths()).data;
};

export const loadIndexValues = async (): Promise<CurrentBalanceHistoryDataPoint> => {
  const conf = getAuthConfiguration();
  const api = new LoadMainDashboardRestAdapterApi(conf);

  return (await api.loadIndexValues()).data;
};

export const loadIncomes = async (
  requestParameters: LoadMainDashboardRestAdapterApiLoadIncomesRequest
): Promise<Array<PaymentDTO>> => {
  const conf = getAuthConfiguration();
  const api = new LoadMainDashboardRestAdapterApi(conf);

  return (await api.loadIncomes(requestParameters)).data;
};

export const loadBonusIncome = async (): Promise<number> => {
  const conf = getAuthConfiguration();
  const api = new LoadMainDashboardRestAdapterApi(conf);

  return (await api.loadBonusIncome()).data;
};

export const loadCountReferrals = async (): Promise<number> => {
  const conf = getAuthConfiguration();
  const api = new LoadMainDashboardRestAdapterApi(conf);

  return (await api.countReferrals()).data;
};

export const loadBonusIncomeHistory = async (
  requestParameters: LoadMainDashboardRestAdapterApiLoadBonusIncomeHistoryRequest
): Promise<PageBonusIncomeRecord> => {
  const conf = getAuthConfiguration();
  const api = new LoadMainDashboardRestAdapterApi(conf);

  return (await api.loadBonusIncomeHistory(requestParameters)).data.pagedData;
};

// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/forgot-password'),
  newPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  main: {
    root: path(ROOTS_DASHBOARD, '/main'),
  },
  profile: {
    root: path(ROOTS_DASHBOARD, '/profile'),
  },
  news: {
    root: path(ROOTS_DASHBOARD, '/news'),
    post: path(ROOTS_DASHBOARD, '/news/:id'),
  },
  affiliate: {
    root: path(ROOTS_DASHBOARD, '/clients'),
    list: path(ROOTS_DASHBOARD, '/clients/list'),
    client: path(ROOTS_DASHBOARD, '/clients/:clientId/dashboard'),
    invite: path(ROOTS_DASHBOARD, '/clients/invite'),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';

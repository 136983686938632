import { getAuthConfiguration } from './auth';
import {
  ManageClientNotesRestAdapterApi, ManageClientNotesRestAdapterApiCreateNewRequest, ManageClientNotesRestAdapterApiDeleteByIdRequest, ManageClientNotesRestAdapterApiUpdateByIdRequest,
} from './generated/partner';

export const createNote = async (
  requestParameters: ManageClientNotesRestAdapterApiCreateNewRequest
): Promise<void> => {
  const conf = getAuthConfiguration();
  const api = new ManageClientNotesRestAdapterApi(conf);

  await api.createNew(requestParameters);
};

export const updateNote = async (
  requestParameters: ManageClientNotesRestAdapterApiUpdateByIdRequest
): Promise<void> => {
  const conf = getAuthConfiguration();
  const api = new ManageClientNotesRestAdapterApi(conf);

  await api.updateById(requestParameters);
};

export const deleteNote = async (
  requestParameters: ManageClientNotesRestAdapterApiDeleteByIdRequest
): Promise<void> => {
  const conf = getAuthConfiguration();
  const api = new ManageClientNotesRestAdapterApi(conf);

  await api.deleteById(requestParameters);
};

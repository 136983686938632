/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AbstractPageableResponseBonusIncomeRecord } from '../model';
// @ts-ignore
import { CurrentBalanceHistoryDataPoint } from '../model';
// @ts-ignore
import { MonthlyResponse } from '../model';
// @ts-ignore
import { PaymentDTO } from '../model';
// @ts-ignore
import { PaymentPayoutDTO } from '../model';
// @ts-ignore
import { UserDTO } from '../model';
/**
 * LoadMainDashboardRestAdapterApi - axios parameter creator
 * @export
 */
export const LoadMainDashboardRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countReferrals: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/count-referrals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBonusIncome: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/load-bonus-income`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {string} [affiliateName] 
         * @param {string} [countryCode] 
         * @param {'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBonusIncomeHistory: async (page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, affiliateName?: string, countryCode?: string, type?: 'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/load-bonus-income-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (localDateFrom !== undefined) {
                localVarQueryParameter['localDateFrom'] = (localDateFrom as any instanceof Date) ?
                    (localDateFrom as any).toISOString().substr(0,10) :
                    localDateFrom;
            }

            if (localDateTo !== undefined) {
                localVarQueryParameter['localDateTo'] = (localDateTo as any instanceof Date) ?
                    (localDateTo as any).toISOString().substr(0,10) :
                    localDateTo;
            }

            if (affiliateName !== undefined) {
                localVarQueryParameter['affiliateName'] = affiliateName;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadIncomes: async (month: number, year: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('loadIncomes', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('loadIncomes', 'year', year)
            const localVarPath = `/main/load-incomes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadIndexValues: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/load-index-values`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadReferralsActivityMonths: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/main/load-referrals-activity-months`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTopReferrals: async (size: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'size' is not null or undefined
            assertParamExists('loadTopReferrals', 'size', size)
            const localVarPath = `/main/load-top-referrals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadWithdrawals: async (month: number, year: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'month' is not null or undefined
            assertParamExists('loadWithdrawals', 'month', month)
            // verify required parameter 'year' is not null or undefined
            assertParamExists('loadWithdrawals', 'year', year)
            const localVarPath = `/main/load-withdrawals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoadMainDashboardRestAdapterApi - functional programming interface
 * @export
 */
export const LoadMainDashboardRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoadMainDashboardRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async countReferrals(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.countReferrals(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadBonusIncome(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadBonusIncome(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {string} [affiliateName] 
         * @param {string} [countryCode] 
         * @param {'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadBonusIncomeHistory(page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, affiliateName?: string, countryCode?: string, type?: 'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponseBonusIncomeRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadBonusIncomeHistory(page, size, sort, localDateFrom, localDateTo, affiliateName, countryCode, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadIncomes(month: number, year: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadIncomes(month, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadIndexValues(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentBalanceHistoryDataPoint>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadIndexValues(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadReferralsActivityMonths(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MonthlyResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadReferralsActivityMonths(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadTopReferrals(size: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadTopReferrals(size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadWithdrawals(month: number, year: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PaymentPayoutDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadWithdrawals(month, year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoadMainDashboardRestAdapterApi - factory interface
 * @export
 */
export const LoadMainDashboardRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoadMainDashboardRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        countReferrals(options?: any): AxiosPromise<number> {
            return localVarFp.countReferrals(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBonusIncome(options?: any): AxiosPromise<number> {
            return localVarFp.loadBonusIncome(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {string} [affiliateName] 
         * @param {string} [countryCode] 
         * @param {'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBonusIncomeHistory(page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, affiliateName?: string, countryCode?: string, type?: 'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND', options?: any): AxiosPromise<AbstractPageableResponseBonusIncomeRecord> {
            return localVarFp.loadBonusIncomeHistory(page, size, sort, localDateFrom, localDateTo, affiliateName, countryCode, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadIncomes(month: number, year: number, options?: any): AxiosPromise<Array<PaymentDTO>> {
            return localVarFp.loadIncomes(month, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadIndexValues(options?: any): AxiosPromise<CurrentBalanceHistoryDataPoint> {
            return localVarFp.loadIndexValues(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadReferralsActivityMonths(options?: any): AxiosPromise<MonthlyResponse> {
            return localVarFp.loadReferralsActivityMonths(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} size 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTopReferrals(size: number, options?: any): AxiosPromise<Array<UserDTO>> {
            return localVarFp.loadTopReferrals(size, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} month 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadWithdrawals(month: number, year: number, options?: any): AxiosPromise<Array<PaymentPayoutDTO>> {
            return localVarFp.loadWithdrawals(month, year, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for loadBonusIncomeHistory operation in LoadMainDashboardRestAdapterApi.
 * @export
 * @interface LoadMainDashboardRestAdapterApiLoadBonusIncomeHistoryRequest
 */
export interface LoadMainDashboardRestAdapterApiLoadBonusIncomeHistoryRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoadMainDashboardRestAdapterApiLoadBonusIncomeHistory
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoadMainDashboardRestAdapterApiLoadBonusIncomeHistory
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoadMainDashboardRestAdapterApiLoadBonusIncomeHistory
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof LoadMainDashboardRestAdapterApiLoadBonusIncomeHistory
     */
    readonly localDateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof LoadMainDashboardRestAdapterApiLoadBonusIncomeHistory
     */
    readonly localDateTo?: string

    /**
     * 
     * @type {string}
     * @memberof LoadMainDashboardRestAdapterApiLoadBonusIncomeHistory
     */
    readonly affiliateName?: string

    /**
     * 
     * @type {string}
     * @memberof LoadMainDashboardRestAdapterApiLoadBonusIncomeHistory
     */
    readonly countryCode?: string

    /**
     * 
     * @type {'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'}
     * @memberof LoadMainDashboardRestAdapterApiLoadBonusIncomeHistory
     */
    readonly type?: 'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'
}

/**
 * Request parameters for loadIncomes operation in LoadMainDashboardRestAdapterApi.
 * @export
 * @interface LoadMainDashboardRestAdapterApiLoadIncomesRequest
 */
export interface LoadMainDashboardRestAdapterApiLoadIncomesRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadMainDashboardRestAdapterApiLoadIncomes
     */
    readonly month: number

    /**
     * 
     * @type {number}
     * @memberof LoadMainDashboardRestAdapterApiLoadIncomes
     */
    readonly year: number
}

/**
 * Request parameters for loadTopReferrals operation in LoadMainDashboardRestAdapterApi.
 * @export
 * @interface LoadMainDashboardRestAdapterApiLoadTopReferralsRequest
 */
export interface LoadMainDashboardRestAdapterApiLoadTopReferralsRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadMainDashboardRestAdapterApiLoadTopReferrals
     */
    readonly size: number
}

/**
 * Request parameters for loadWithdrawals operation in LoadMainDashboardRestAdapterApi.
 * @export
 * @interface LoadMainDashboardRestAdapterApiLoadWithdrawalsRequest
 */
export interface LoadMainDashboardRestAdapterApiLoadWithdrawalsRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadMainDashboardRestAdapterApiLoadWithdrawals
     */
    readonly month: number

    /**
     * 
     * @type {number}
     * @memberof LoadMainDashboardRestAdapterApiLoadWithdrawals
     */
    readonly year: number
}

/**
 * LoadMainDashboardRestAdapterApi - object-oriented interface
 * @export
 * @class LoadMainDashboardRestAdapterApi
 * @extends {BaseAPI}
 */
export class LoadMainDashboardRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadMainDashboardRestAdapterApi
     */
    public countReferrals(options?: any) {
        return LoadMainDashboardRestAdapterApiFp(this.configuration).countReferrals(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadMainDashboardRestAdapterApi
     */
    public loadBonusIncome(options?: any) {
        return LoadMainDashboardRestAdapterApiFp(this.configuration).loadBonusIncome(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadMainDashboardRestAdapterApiLoadBonusIncomeHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadMainDashboardRestAdapterApi
     */
    public loadBonusIncomeHistory(requestParameters: LoadMainDashboardRestAdapterApiLoadBonusIncomeHistoryRequest = {}, options?: any) {
        return LoadMainDashboardRestAdapterApiFp(this.configuration).loadBonusIncomeHistory(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.localDateFrom, requestParameters.localDateTo, requestParameters.affiliateName, requestParameters.countryCode, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadMainDashboardRestAdapterApiLoadIncomesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadMainDashboardRestAdapterApi
     */
    public loadIncomes(requestParameters: LoadMainDashboardRestAdapterApiLoadIncomesRequest, options?: any) {
        return LoadMainDashboardRestAdapterApiFp(this.configuration).loadIncomes(requestParameters.month, requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadMainDashboardRestAdapterApi
     */
    public loadIndexValues(options?: any) {
        return LoadMainDashboardRestAdapterApiFp(this.configuration).loadIndexValues(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadMainDashboardRestAdapterApi
     */
    public loadReferralsActivityMonths(options?: any) {
        return LoadMainDashboardRestAdapterApiFp(this.configuration).loadReferralsActivityMonths(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadMainDashboardRestAdapterApiLoadTopReferralsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadMainDashboardRestAdapterApi
     */
    public loadTopReferrals(requestParameters: LoadMainDashboardRestAdapterApiLoadTopReferralsRequest, options?: any) {
        return LoadMainDashboardRestAdapterApiFp(this.configuration).loadTopReferrals(requestParameters.size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadMainDashboardRestAdapterApiLoadWithdrawalsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadMainDashboardRestAdapterApi
     */
    public loadWithdrawals(requestParameters: LoadMainDashboardRestAdapterApiLoadWithdrawalsRequest, options?: any) {
        return LoadMainDashboardRestAdapterApiFp(this.configuration).loadWithdrawals(requestParameters.month, requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }
}

// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// components
import Label from 'src/components/Label';
import SvgIconStyle from 'src/components/SvgIconStyle';
import { TFunction } from 'i18next';

// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
};

const navConfig = (t: TFunction, inboxCount: number) => [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: t('nav.dashboard'),
        path: PATH_DASHBOARD.main.root,
        icon: ICONS.dashboard,
      },
      {
        title: t('nav.clients'),
        path: PATH_DASHBOARD.affiliate.root,
        icon: ICONS.user,
      },
      {
        title: t('nav.news'),
        path: PATH_DASHBOARD.news.root,
        icon: ICONS.mail,
        info:
          inboxCount > 0 ? (
            <Label variant="outlined" color="error">
              +{inboxCount}
            </Label>
          ) : (
            <></>
          ),
      },
    ],
  },
];

export default navConfig;

/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AbstractPageableResponseUserSimplifiedDTO } from '../model';
// @ts-ignore
import { UserDTO } from '../model';
/**
 * LoadUsersRestAdapterApi - axios parameter creator
 * @export
 */
export const LoadUsersRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateTreeById: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('affiliateTreeById', 'id', id)
            const localVarPath = `/users/affiliate-tree/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fullName] 
         * @param {string} [parentFullName] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSimplified: async (fullName?: string, parentFullName?: string, email?: string, phone?: string, page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users/list-simplified`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fullName !== undefined) {
                localVarQueryParameter['fullName'] = fullName;
            }

            if (parentFullName !== undefined) {
                localVarQueryParameter['parentFullName'] = parentFullName;
            }

            if (email !== undefined) {
                localVarQueryParameter['email'] = email;
            }

            if (phone !== undefined) {
                localVarQueryParameter['phone'] = phone;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoadUsersRestAdapterApi - functional programming interface
 * @export
 */
export const LoadUsersRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoadUsersRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async affiliateTreeById(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.affiliateTreeById(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fullName] 
         * @param {string} [parentFullName] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listSimplified(fullName?: string, parentFullName?: string, email?: string, phone?: string, page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponseUserSimplifiedDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listSimplified(fullName, parentFullName, email, phone, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoadUsersRestAdapterApi - factory interface
 * @export
 */
export const LoadUsersRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoadUsersRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        affiliateTreeById(id: number, options?: any): AxiosPromise<Array<UserDTO>> {
            return localVarFp.affiliateTreeById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fullName] 
         * @param {string} [parentFullName] 
         * @param {string} [email] 
         * @param {string} [phone] 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listSimplified(fullName?: string, parentFullName?: string, email?: string, phone?: string, page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<AbstractPageableResponseUserSimplifiedDTO> {
            return localVarFp.listSimplified(fullName, parentFullName, email, phone, page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for affiliateTreeById operation in LoadUsersRestAdapterApi.
 * @export
 * @interface LoadUsersRestAdapterApiAffiliateTreeByIdRequest
 */
export interface LoadUsersRestAdapterApiAffiliateTreeByIdRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadUsersRestAdapterApiAffiliateTreeById
     */
    readonly id: number
}

/**
 * Request parameters for listSimplified operation in LoadUsersRestAdapterApi.
 * @export
 * @interface LoadUsersRestAdapterApiListSimplifiedRequest
 */
export interface LoadUsersRestAdapterApiListSimplifiedRequest {
    /**
     * 
     * @type {string}
     * @memberof LoadUsersRestAdapterApiListSimplified
     */
    readonly fullName?: string

    /**
     * 
     * @type {string}
     * @memberof LoadUsersRestAdapterApiListSimplified
     */
    readonly parentFullName?: string

    /**
     * 
     * @type {string}
     * @memberof LoadUsersRestAdapterApiListSimplified
     */
    readonly email?: string

    /**
     * 
     * @type {string}
     * @memberof LoadUsersRestAdapterApiListSimplified
     */
    readonly phone?: string

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoadUsersRestAdapterApiListSimplified
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoadUsersRestAdapterApiListSimplified
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoadUsersRestAdapterApiListSimplified
     */
    readonly sort?: Array<string>
}

/**
 * LoadUsersRestAdapterApi - object-oriented interface
 * @export
 * @class LoadUsersRestAdapterApi
 * @extends {BaseAPI}
 */
export class LoadUsersRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {LoadUsersRestAdapterApiAffiliateTreeByIdRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadUsersRestAdapterApi
     */
    public affiliateTreeById(requestParameters: LoadUsersRestAdapterApiAffiliateTreeByIdRequest, options?: any) {
        return LoadUsersRestAdapterApiFp(this.configuration).affiliateTreeById(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadUsersRestAdapterApiListSimplifiedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadUsersRestAdapterApi
     */
    public listSimplified(requestParameters: LoadUsersRestAdapterApiListSimplifiedRequest = {}, options?: any) {
        return LoadUsersRestAdapterApiFp(this.configuration).listSimplified(requestParameters.fullName, requestParameters.parentFullName, requestParameters.email, requestParameters.phone, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }
}

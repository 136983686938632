// @mui
import { Paper, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useFormik } from 'formik';
import moment from 'moment';
import React, { useState } from 'react';
// hooks
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { useUrlQuery } from 'src/hooks/useUrlQuery';
import RegisterDataStep from './RegisterDataStep';
import RegisterFinishStep from './RegisterFinishStep';
import { RegisterDataSchema, RegisterDocumentSchema } from './yupSchema';

// ----------------------------------------------------------------------

const steps = ['Personal data', /**  'Documents',*/ 'Complete registration'];

export type DataInitialValues = {
  username: string;
  password: string;
  confirmPassword: string;
  countryCode: string;
  birthday: string;
  name: string;
  surname: string;
  phone: string;
  email: string;
  afterSubmit?: string;
};

export type DocumentInitialValues = {
  personalDocument: File | null;
  proofOfAddressDocument: File | null;
  afterSubmit?: string;
};

export default function RegisterForm() {
  const { register } = useAuth();
  const urlQuery = useUrlQuery();

  const token = urlQuery.get('token');

  const isMountedRef = useIsMountedRef();

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const formikData = useFormik<DataInitialValues>({
    initialValues: {
      username: '',
      password: '',
      confirmPassword: '',
      countryCode: '',
      birthday: '',
      name: '',
      surname: '',
      phone: '',
      email: '',
    },
    validationSchema: RegisterDataSchema,
    onSubmit: async () => {
      // handleNext();
      try {
        await register({
          token: token || '66c4d8a1-d969-46cb-8b6d-028249166cbd',
          username: formikData.values.username,
          password: formikData.values.password,
          countryCode: formikData.values.countryCode,
          //TODO: remove moment and change to date-fns,
          birthday: moment(formikData.values.birthday).toISOString(),
          name: formikData.values.name,
          surname: formikData.values.surname,
          phone: formikData.values.phone,
          email: formikData.values.email,
          // personalDocument: values.personalDocument,
          // proofOfAddressDocument: values.proofOfAddressDocument,
        });

        handleNext();

        if (isMountedRef.current) {
          formikData.setSubmitting(false);
        }
      } catch (error) {
        if (isMountedRef.current) {
          formikData.setErrors({ afterSubmit: error.response.data.message });
          formikData.setSubmitting(false);
        }
      }
    },
  });
  const formikDocuments = useFormik<DocumentInitialValues>({
    initialValues: {
      personalDocument: null,
      proofOfAddressDocument: null,
    },
    validationSchema: RegisterDocumentSchema,
    onSubmit: async () => {
      try {
        await register({
          token: token || 'e6c6a416-9fc1-4add-95f8-c79b2ebfb4fc',
          username: formikData.values.username,
          password: formikData.values.password,
          countryCode: formikData.values.countryCode,
          //TODO: remove moment and change to date-fns,
          birthday: moment(formikData.values.birthday).toISOString(),
          name: formikData.values.name,
          surname: formikData.values.surname,
          phone: formikData.values.phone,
          email: formikData.values.email,
          // personalDocument: values.personalDocument,
          // proofOfAddressDocument: values.proofOfAddressDocument,
        });

        handleNext();

        if (isMountedRef.current) {
          formikDocuments.setSubmitting(false);
        }
      } catch (error) {
        if (isMountedRef.current) {
          error.response.data.subErrors.forEach((item: any) => {
            formikData.setFieldError(item.field.toString(), item.message);
          });
          formikDocuments.setSubmitting(false);
        }
      }
    },
  });

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <>
            <RegisterDataStep formik={formikData} />
          </>
        );
      // case 1:
      //   return (
      //     <>
      //       <RegisterDocumentStep formik={formikDocuments} />
      //     </>
      //   );
      case 1:
        return (
          <>
            <RegisterFinishStep email={formikData.values.email} />
          </>
        );

      default:
        return 'Unknown step';
    }
  };

  return (
    <>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => {
          const stepProps: { completed?: boolean } = {};
          const labelProps: {
            optional?: React.ReactNode;
          } = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <div>
        {activeStep === steps.length ? (
          <>
            <Paper sx={{ p: 3, my: 3, minHeight: 120, bgcolor: 'grey.50012' }}>
              <Typography sx={{ my: 1 }}>
                All steps completed - visit your email {formikData.values.email}, finish
                registration
              </Typography>
            </Paper>
          </>
        ) : (
          <>
            <div>{getStepContent(activeStep)}</div>
          </>
        )}
      </div>
    </>
  );
}

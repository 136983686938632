import { getAuthConfiguration } from './auth';
import {
  AffiliateNetworkHeadResponse,
  AffiliateNetworkNodeDTO,
  AffiliateNetworkNodeSimplifiedDTO,
  LoadAffiliateTreeRestAdapterApi,
  LoadAffiliateTreeRestAdapterApiGetAffiliateSearchResultRequest,
  LoadAffiliateTreeRestAdapterApiGetFirstLineRequest,
  LoadAffiliateTreeRestAdapterApiGetSimplifiedRequest,
} from './generated/common';

export type AffiliateNetworkType = AffiliateNetworkNodeDTO & {
  items?: AffiliateNetworkType[];
};

export const loadTopAffiliate = async (): Promise<Array<AffiliateNetworkNodeDTO>> => {
  const conf = getAuthConfiguration();
  const api = new LoadAffiliateTreeRestAdapterApi(conf);

  return (await api.getTop()).data;
};

export const loadSimplifiedAffiliate = async (
  requestParameters: LoadAffiliateTreeRestAdapterApiGetSimplifiedRequest
): Promise<Array<AffiliateNetworkNodeSimplifiedDTO>> => {
  const conf = getAuthConfiguration();
  const api = new LoadAffiliateTreeRestAdapterApi(conf);

  return (await api.getSimplified(requestParameters)).data;
};

export const loadHeadAffiliate = async (): Promise<AffiliateNetworkHeadResponse> => {
  const conf = getAuthConfiguration();
  const api = new LoadAffiliateTreeRestAdapterApi(conf);

  return (await api.getHead()).data;
};

export const loadLineAffiliate = async (
  requestParameters: LoadAffiliateTreeRestAdapterApiGetFirstLineRequest
): Promise<Array<AffiliateNetworkNodeDTO>> => {
  const conf = getAuthConfiguration();
  const api = new LoadAffiliateTreeRestAdapterApi(conf);

  return (await api.getFirstLine(requestParameters)).data;
};

export const loadAffiliateSearch = async (
  requestParameters: LoadAffiliateTreeRestAdapterApiGetAffiliateSearchResultRequest
): Promise<Array<AffiliateNetworkNodeDTO>> => {
  const conf = getAuthConfiguration();
  const api = new LoadAffiliateTreeRestAdapterApi(conf);

  return (await api.getAffiliateSearchResult(requestParameters)).data;
};
